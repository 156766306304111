import Layout from "../components/layout/layout.jsx"
import Cover from "../components/cover/cover.jsx"
import SEO from "../components/seo.jsx"
import React from "react"

const IndexPage = () => (
  <Layout>
    <SEO title="Offical" />
    <Cover />
  </Layout>
)

export default IndexPage
