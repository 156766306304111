import { StaticQuery, graphql } from 'gatsby'
import coverStyles from './cover.module.sass'
import Img from "gatsby-image"
import React from 'react'
import { Video } from '../../utilities/video'

// IMPORT: images and videos
import ArtworkLoop from '../../videos/cover-dance.mp4';
import Cybgtm from '../../images/cybgtm-text.svg';

const Cover = () => (
  <StaticQuery
    query={graphql`
    query {
      artworkFrame: file(relativePath: { eq: "artwork-frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      coverDance: file(relativePath: { eq: "cover-dance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            src
            }
          }
        }
      }
    `}
    render={data => (
      <div className={coverStyles.container}>
        <Cybgtm className={coverStyles.cybgtm} />
        <Video width={'100%'} height={'auto'} poster={data.coverDance.childImageSharp.fluid.src} className={coverStyles.artworkLoop} src={[ArtworkLoop]}/>
        <Img className={coverStyles.artworkFrame} fluid={data.artworkFrame.childImageSharp.fluid} />
      </div >
    )}
  />
)

export default Cover;